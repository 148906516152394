<template>
  <div id="app">
    <Nav />
    <div class="page-content">
      <router-view />
    </div>
    <Footer />
    <ContactModal />
    <FeedbackModal />
    <TryDemo />
  </div>
</template>


<script>
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";
import ContactModal from "@/components/modals/ContactModal.vue";
import FeedbackModal from "@/components/modals/FeedbackModal.vue";
import TryDemo from "@/components/modals/TryDemo.vue";

export default {
  name: "HomeView",
  components: {
    Nav,
    Footer,
    ContactModal,
    FeedbackModal,
    TryDemo,
  },
  mounted(){
    this.getContactToken();
  },
  methods:{
    getContactToken(){
      this.$store.dispatch("getContactToken");
    },
  },

};
</script>



<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800;900&family=Roboto:wght@100;300;400&display=swap'); 


// *{
//   border: 1px solid red;
// }
::-moz-selection { /* Code for Firefox */
  color: white;
  background: #EC6A69;
}

::selection {
  color: white;
  background: #EC6A69;
}

html{
  font-size: 62.5%;
}

body{
  margin: 0;
  padding: 0;
  background-color: #f6f6f6;
}

#app {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #002e46;
  font-weight: 300;
  font-size: 1.6rem;

}

.page-content{
  min-height: calc(100vh - 175px);
}

.is-300{
 font-weight: 300;
}
.is-800{
  font-weight: 800;
}

.clickable{
  cursor: pointer;
}

.t-center{
  text-align: left;
}
.t-right{
  text-align: right;
}
//center on mobile
@media (max-width: 768px) {
  .t-center{
    text-align: center;
  }
  .t-right{
    text-align: center;
  }
} 

nav {
  padding: 30px;

  a {
    color: #002e46;

    &.router-link-exact-active {
      color: #002e46;
      // text-decoration: underline;
    }
  }
}


.content {
  max-width: 1600px;
  position: relative;
  margin: 0 auto;
  padding: 0 30px;
}

.big-text{
  font-family: 'Poppins', sans-serif;
  font-size: 10rem;
  font-weight: 900;
  line-height: 1.2;
  margin-top: 30px;
  color: #abb7c4;
  line-height: 1;
  text-transform: uppercase;
}

.norm-text{
  font-family: 'Roboto', sans-serif;
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.2;
  margin-top: 30px;
  color: #002e46;
  line-height: 1.25;
}

//remove all default button styles
.btn{
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: all 0.1s ease-in-out;
  &:hover{
    transform: scale(1.1);
  }
}

.btn{
  font-size: 2rem;
  font-weight: 800;
  text-transform: uppercase;
}
.btn{
  // width: 400px;
  // height: 100px;
  padding: 0 40px;
  font-size: 30px;
  text-align: center;
  line-height: 40px;
  color: rgba(255,255,255,0.9);
  border-radius: 50px;
  background: linear-gradient(-45deg, #ffa63d, #ff3d77, #338aff, #3cf0c5);
  background-size: 600%;
  -webkit-animation: anime 16s linear infinite;
          animation: anime 16s linear infinite;
}
.btn::after {
  width: 400px;
  height: 100px;
  position: absolute;
  margin-top: -70px;
  z-index: -1;
  filter: blur(30px);
  opacity: 0.8;
  background: linear-gradient(-45deg, #ffa63d, #ff3d77, #338aff, #3cf0c5);
}

//dynamic width button for btn, btn2
.btn-dynamic{
  // width: 100%!important;

}


@-webkit-keyframes anime {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes anime {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}



//desktop 

@media (min-width: 1350px) {
  .btn{
    line-height: 100px;
  }
}


//mobile 

@media (max-width: 768px) {
  .big-text{
    font-size: 5rem;
  }
  .norm-text{
    font-size: 2rem;
  }
  .btn{
    font-size: 1.6rem;
    height: 60px;
    line-height: 60px;
  }
}


//base
$sizeUnit: rem;
$marginKey: 'm';
$paddingKey: 'p';
$separator: '-';
$sizes: (
    ('none', 0),
    ('xxs', 0.125),
    ('xs', 0.25),
    ('sm', 0.5),
    ('md', 1),
    ('lg', 2),
    ('xl', 4),
    ('xxl', 8),
);
$positions: (
    ('t', 'top'),
    ('r', 'right'),
    ('b', 'bottom'),
    ('l', 'left')
);

@function sizeValue($key, $value) {
    @return if($key == 'none', 0, $value + $sizeUnit);
}

@each $size in $sizes {
    $sizeKey: nth($size, 1);
    $sizeValue: nth($size, 2);
    .#{$marginKey}#{$separator}#{$sizeKey} {
        margin: sizeValue($sizeKey, $sizeValue);
    }
    .#{$paddingKey}#{$separator}#{$sizeKey} {
        padding: sizeValue($sizeKey, $sizeValue);
    }
    @each $position in $positions {
        $posKey: nth($position, 1);
        $posValue: nth($position, 2);
        .#{$marginKey}#{$separator}#{$posKey}#{$separator}#{$sizeKey} {
            margin-#{$posValue}: sizeValue($sizeKey, $sizeValue);
        }
        .#{$paddingKey}#{$separator}#{$posKey}#{$separator}#{$sizeKey} {
            padding-#{$posValue}: sizeValue($sizeKey, $sizeValue);
        }
    }
}

//rows and columns
.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem;
}
.col {
  flex: 1;
  padding: 0 0.5rem;
}
.col-center {
  display: flex;
  justify-content: center;
}

//colapse row on mobile add spacing
@media (max-width: 768px) {
  .row {
    flex-direction: column;
  }
  .col {
    padding: 1rem 0.25rem;
  }
}


.text-content {
  padding-top: 100px;
  max-width: 800px;
  margin: 0 auto;
}

.templates-content{
  padding-top: 100px;
  max-width: 1620px;
  margin: 0 auto;
}

@media (max-width: 1620px) {
  
  .templates-content{
    padding: 50px 20px 200px 20px;
  }
  
}

@media (max-width: 768px) {
  
  .text-content{
    padding: 50px 20px 200px 20px;
  }
  
}

.text-content a {
  color: #ED6969;
}

.text-content a:hover {
  color: #002e46;
}



//forms css ====================================================================
//==============================================================================

.form-popup-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
.form-popup-bg {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
  overflow-y: auto;
  z-index: 10000;
}
.form-popup-bg.is-visible {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0s;
  transition: opacity 0.3s 0s, visibility 0s 0s;
}
.form-container {
  background-color:#fff;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding: 40px;
  color: #002e46;
}

.form-popup-bg:before {
  content: '';
  background-color: #fff;
  opacity: 0.25;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}


/* Style the form container */
/* form {
    width: 100%;
    max-width: 400px;
    margin: 30px auto; 
    padding: 20px;
    border: 1px solid #e0e0e0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1); 
} */

/* Style the form-group to give spacing */
.form-group {
    margin-bottom: 20px;
}

/* Style the form labels */
.form-group label {
    font-family: "Roboto", sans-serif;
    display: block;
    margin-bottom: 8px;
    font-weight: 400;
    color: #002e46;  /* White */
}

/* Style the form input fields */
.form-group input.form-control {
    width: 95%;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.2);  /* Slightly more transparent than the form */
    color: #002e46;  /* White */
    transition: background-color 0.3s ease-in-out;
}

/* Add hover and focus effect on input fields */
.form-group input.form-control:hover,
.form-group input.form-control:focus {
    background-color: rgba(255, 255, 255, 0.3);  /* Even less transparent on hover/focus */
    outline: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.form-group textarea.form-control {
    width: 95%;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.2);  /* Slightly more transparent than the form */
    color: #002e46;  /* White */
    transition: background-color 0.3s ease-in-out;
    resize: vertical;  /* Allow only vertical resizing of the textarea */
}

/* Add hover and focus effect on textarea */
.form-group textarea.form-control:hover,
.form-group textarea.form-control:focus {
    background-color: rgba(255, 255, 255, 0.3);  /* Even less transparent on hover/focus */
    outline: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

/* Style the submit button */
.form-bt {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    padding: 10px 15px;
    background-color: #002e46;  /* Blue */
    color: #fff;  /* White */
    font-size: 2rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    
}

/* Hover effect for the submit button */
.form-bt:hover {
    background-color: #EC6A69;  /* Darker blue */
    color: #ffffff;  /* White */
}

.form-buttons{
  display: flex;
  justify-content: end;
}

.close-button {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 10px;
  right: 5px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.close-button svg {
    fill: none;
    stroke: #002e46;  /* White stroke color for the X */
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.close-button:hover svg {
    stroke: #EC6A69; /* Lighter color on hover for some feedback */
}

.modal-title{
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-size: 4rem;
  color: #abb7c4;
  margin-bottom: 20px;
  text-transform: uppercase;
}

/*mobile css*/

@media (max-width: 768px) {
  
  .form-container{
    max-width: 280px;
  }
  
}

</style>
