<template>
  <div class="home">
    <img class="logo-header" alt="Proofing Monkey" src="img/logo.svg" />
    <div class="big-text">
      WE ARE YOUR 3D <br/> PROOFING PLATFORM
    </div>
    <div class="button-wrap m-t-xxl">
      <button class="btn" @click="toggleForm('trydemo')">Watch Tutorial</button>
      <button class="btn" @click="toggleForm('trydemo')">Try Demo</button>
      <button class="btn" @click="goto('templates')">Download Templates</button>
    </div>
    <div class="content">
      <div class="three-scene m-t-xxl bigvid">
        <video autoplay muted loop>
          <source src="video/ProofingMonkey_Tutorial.mp4" type="video/mp4">
        </video>
      </div>

      <div class="big-text m-t-xxl">
        DO YOU OR YOUR<br/>
        CLIENTS REQUIRE<br/>
        REAL TIME PROOFING<br/>
        OF WHAT A PRODUCT<br/>
        COULD LOOK LIKE<br/>
        WRAPPED?
      </div>

      <div class="norm-text">
        Proofing Monkey strives to give you control over building, viewing, sharing, and<br/>
        approving a 3D visual of a branded piece of printed collateral. From promotional<br/>
        items to fleet vehicles, we help streamline the process and get you and your<br/>
        clients excited about the product's potential appearance before printing.<br/><br/>

        Our Proofing System allows print shops to preview and proof artwork for clients<br/>
        on a 3D object with high precision, taking bleed into account. Additionally, our<br/>
        Proofing System helps you create a comprehensive archive of all proofs, which<br/>
        is easy to search and navigate for both you and your clients.
      </div>

      <div class="samples-slider m-t-xxl">
        <div class="slider-item">
          <video autoplay muted loop>
            <source src="video/coffeeCup.mp4" type="video/mp4">
          </video>
        </div>
        <div class="slider-item">
          <video autoplay muted loop>
              <source src="video/tent.mp4" type="video/mp4">
            </video>
        </div>
        <div class="slider-item">
          <video autoplay muted loop>
              <source src="video/tShirt.mp4" type="video/mp4">
            </video>
        </div>
      </div>


        <!-- <div class="samples-bento-grid m-t-xxl">
          <div class="bento-item item1">
            <video autoplay muted loop>
              <source src="video/BeerCan.mp4" type="video/mp4">
            </video>
          </div>
          <div class="bento-item item2">
            <video autoplay muted loop>
              <source src="video/Hat.mp4" type="video/mp4">
            </video>
          </div>
          <div class="bento-item item3">
            <video autoplay muted loop>
              <source src="video/PizzaBox.mp4" type="video/mp4">
            </video>
          </div>
          <div class="bento-item item4">
            <video autoplay muted loop>
              <source src="video/Tshirt.mp4" type="video/mp4">
            </video>
          </div>
        </div> -->


      <div class="big-text m-t-xxl">
        WHAT USE CASES<br/>
        CAN WE COVER?
      </div>

      <div class="norm-text">
        At Proofing Monkey, we are dedicated to assisting various end<br/>
        users in meeting their proofing requirements. This tool is ideal<br/>
        for different business models. Here is just a sample of who<br/>
        could benefit from Proofing Monkey:
      </div>

      <div class="list-wrap">
          <ul class="list">
            <li>Print &amp; Signage Shops</li>
            <li>Packaging Manufacturers</li>
            <li>Vehicle Wrap Shops</li>
            <li>Interior Design Studios</li>
            <li>Architecture Firms</li>
            <li>Restaurant Franchises</li>
            <li>E-commerce Retailers</li>
            <li>Product Design Firms</li>
            <li>Manufacturing Companies</li>
            <li>Entertainment Industry</li>
            <li>Virtual Reality Developers</li>
            <li>Fashion Industry</li>
          </ul>
        </div>


        <div class="col col-center">
          <button class="btn" @click="toggleForm('feedback')">LEAVE US A FEEDBACK!</button>
        </div>
     

        <div class="three-scene m-t-xxl bigvid">
        <video autoplay muted loop>
          <source src="video/ProofingMonkey_Tutorial.mp4" type="video/mp4">
        </video>
      </div>


      <div class="big-text m-t-xxl">
        ARE YOU JUST<br/>
        LOOKING TO TRY OUR<br/>
        PLATFORM OUT?<br/>
        MAYBE A LITTLE<br/>
        CURIOUS ON WHAT<br/>
        IT IS ALL ABOUT?
      </div>

      <div class="norm-text">
        We invite you to take it for a test run without needing to sign up. We<br/>
        simply ask for your feedback to help us improve the system.
      </div>

      <!-- <div class="m-t-xxl m-b-xxl">
      <div class="row">
        <div class="col col-center">
          <button class="btn" @click="toggleForm('trydemo')">Download Templates</button>
        </div>
        <div class="col col-center">
          <button class="btn" @click="toggleForm('trydemo')">Try Demo</button>
        </div>
        <div class="col col-center">
          <button class="btn" @click="toggleForm('feedback')">LEAVE US A FEEDBACK!</button>
        </div>
      </div>
    </div> -->
    <div class="button-wrap m-t-xxl m-b-xxl">
      <button class="btn" @click="goto('templates')">Download Templates</button>
      <button class="btn" @click="toggleForm('trydemo')">Try Demo</button>
      <button class="btn" @click="toggleForm('feedback')">LEAVE US A FEEDBACK!</button>
    </div>
  </div>
  </div>
</template>

<script>

import ThreeDView from '@/components/ThreeDView.vue';
export default {
  metaInfo: {
      title: 'Proofing Monkey',
      htmlAttrs: {
        lang: 'en',        
      },
      meta: [
          {
            name: "description",
            content: "We are Your 3D Proofing Platform. Proofing Monkey strives to give you control of building, viewing, sharing and approving a 3D visual of a beautiful branded piece of printed collateral."
          },
          {
            property: "og:title",
            content: "Proofing Monkey - We are Your 3D Proofing Platform.",
            vmid: "og:title"
          },
          {
            property: "og:description",
            content: "Proofing Monkey strives to give you control of building, viewing, sharing and approving a 3D visual of a beautiful branded piece of printed collateral.",
            vmid: "og:description"
          },
          {
            property: "og:image",
            content: "https://proofingmonkey.com/img/og-share.png",
            vmid: "og:image"
          },
          {
            property: "og:url",
            content: "https://proofingmonkey.com",
            vmid: "og:url"
          }
        ]
  },
  name: "HomeView",
  components: {
    ThreeDView,
  },
  methods: {
    toggleForm(form) {
      this.$store.commit("toggleModal", form);
    },
    goto(page){
      this.$router.push(page)
    }
  },
};
</script>

<style lang="scss">
.home{
  text-align: center;
}
.logo-header{
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: block;
  padding: 30px;
}
//shrinc on mobile
@media (max-width: 768px) {
  .logo-header{
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    display: block;
    padding: 30px;
  }
}

.button-wrap{
  display: flex;
  justify-content: center;
  width: 100%;
}
//space between children
.button-wrap button:not(:last-child){
  margin-right: 30px;
}

@media (max-width: 768px) {
  .button-wrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .button-wrap button{
    margin-right: 10px !important;
    margin-left: 10px;
  }
  //space between children
  .button-wrap button:not(:last-child){
    margin-bottom: 20px;
  }
}


.three-scene{
  position: relative;
  width: 100%;
  height: 800px;
  background-color: #fff;
  border-radius: 60px;
  border: 2px solid #fff;
  box-shadow: 0px 0px 12px -3px rgb(177 177 177 / 75%);
  overflow: hidden;
}

.three-scene video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: 100%;
  transform: translate(-50%, -50%);
  object-fit: cover; /* Ensures video covers the whole area */
}

.item1 { grid-area: first; }
.item2 { grid-area: second; }
.item3 { grid-area: third; }
.item4 { grid-area: forth; }

.samples-bento-grid{
  display: grid;
  // grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    'first first first first second second'
    'third third forth forth forth forth';
  grid-gap: 20px;
}
.bento-item {
  width: 100%;
  height: 400px;
  border-radius: 10px;
  position: relative;
  overflow: hidden; /* To maintain border-radius */
  background-color: #fff;
}

.bento-item video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: 100%;
  transform: translate(-50%, -50%);
  object-fit: cover; /* Ensures video covers the whole area */
}


.samples-slider{
  display: flex;
  justify-content: space-between;
}
.slider-item{
  width: 100%;
  height: 400px;
  background-color: #fff;
  border-radius: 60px;
  position: relative;
  overflow: hidden; /* To maintain border-radius */
  border: 2px solid #fff;
  box-shadow: 0px 0px 12px -3px rgb(177 177 177 / 75%);
}
//item spacing not last
.slider-item:not(:last-child){
  margin-right: 20px;
}

.slider-item video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: 100%;
  transform: translate(-50%, -50%);
  object-fit: cover; /* Ensures video covers the whole area */
}

//mobile colapse grid

@media (max-width: 768px) {
  .samples-bento-grid{
    grid-template-areas:
      'first first first first first first'
      'second second second second second second'
      'third third third third third third'
      'forth forth forth forth forth forth';
  }
  .samples-slider{
    flex-direction: column;
  }
  .slider-item:not(:last-child){
    margin-right: 0;
    margin-bottom: 20px;
  }
}

//split list in two columns
.list {
  font-family: 'Roboto', sans-serif;
  font-size: 4rem;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  font-size: 3rem;
  color: #abb7c4;
  font-weight: 400;
}

.list-wrap{

  display: flex;
  padding: 30px;
  text-align: left;
  justify-content: center;
}

//colapse list on mobile
@media (max-width: 768px) {
  .list {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
    font-size: 2rem;
  }
}

</style>
